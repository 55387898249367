<template>
  <button
    class="bg-white w-full bg-blue h-14 border border-blue-700 text-blue-600 hover:bg-indigo-700 hover:text-white text-l cursor-pointer text-center uppercase my-2 text-sm"
    :class="{ '!bg-indigo-500 text-white hover:bg-indigo-500': isActive }"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "FilterButton",
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>
