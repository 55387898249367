<template>
  <div class="qtEduEntries w-full">
    <LSection :use-margin="false">
      <template #body>
        <div class="heading1 !text-black pt-6">
          <div class="flex justify-between items-baseline">
            Programs
            <div class="float-right !text-xl">
              {{ filteredPrograms.length }} Items
            </div>
          </div>
          <hr class="gradientLine" />
        </div>

        <div class="flex justify-between gap-8 h8:flex-row flex-col-reverse">
          <div class="flex flex-col gap-8 w-full">
            <LProgramCard
              class=""
              v-for="(program, index) in paginatedData"
              :key="index"
              :program="program"
            ></LProgramCard>
          </div>
        </div>
        <div class="flex flex-wrap justify-center gap-2 py-4 relative">
          <div
            v-for="number in pages"
            :key="number"
            class="button !text-sm !px-2 !py-1 w-8 !rounded-sm"
            :class="number === page ? 'main' : 'secondary'"
            @click="page = number"
          >
            {{ number }}
          </div>
        </div>
      </template>
    </LSection>
  </div>
</template>

<script>
import LSection from "@/components/layout/LSection.vue";
import { useUserPermissions } from "@/composables/useUserPermissions";
import { countries } from "@/components/Organisation/lib/countries";
import { useQTEduResources } from "@/composables/qtEdu/useQTEduResources";
import { computed, ref, watch, watchEffect } from "vue";
import LProgramCard from "@/components/qtEdu/LProgramCard.vue";
import { isArray } from "@/lib/getVariableType";

export default {
  name: "QtEduEntries",
  components: {
    LProgramCard,
    LSection,
  },
  emits: ["languages", "targets"],
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const qt = useQTEduResources();
    const { isLoggedIn, user } = useUserPermissions();
    const qtEduPrograms = useQTEduResources();
    const filteredPrograms = ref(qt.programs);
    const languages = ref([]);
    const targets = ref([]);

    const page = ref(1);
    const perPage = 6;

    const filterPrograms = () => {
      const hasFilter = Object.keys(props.filter).length;
      if (!hasFilter) {
        filteredPrograms.value = qt.programs;
        languages.value.push(
          ...filteredPrograms.value
            .map((p) => {
              return p.lang;
            })
            .reduce((arr1, arr2) => {
              return [...new Set([...arr1, ...arr2])];
            }, [])
        );
        targets.value.push(
          ...filteredPrograms.value
            .map((p) => {
              return p.target;
            })
            .reduce((arr1, arr2) => {
              return [...new Set([...arr1, ...arr2])];
            }, [])
        );
        emit("languages", languages);
        emit("targets", targets);
      } else {
        filteredPrograms.value = qt.programs.filter((p) => {
          return Object.keys(props.filter).reduce((pass, k) => {
            if (isArray(props.filter[k])) {
              if (isArray(p[k])) {
                return (
                  pass &&
                  props.filter[k].filter((val) => p[k].includes(val)).length > 0
                );
              }
              return pass && props.filter[k].includes(p[k]);
            } else if (isArray(p[k])) {
              return pass && p[k].includes(props.filter[k]);
            }
            return pass && p[k] === props.filter[k];
          }, true);
        });
        emit("languages", languages);
        emit("targets", targets);
      }
    };
    watchEffect(filterPrograms);

    const paginatedData = computed(() => {
      const start = (page.value - 1) * perPage;
      const end = start + perPage;
      return filteredPrograms.value.slice(start, end);
    });
    const pages = computed(() =>
      Math.ceil(filteredPrograms.value.length / perPage)
    );

    watch(
      () => props.filter,
      () => {
        page.value = 1;
      },
      { deep: true }
    );
    return {
      qt,
      countries,
      user,
      isLoggedIn,
      qtEduPrograms,
      filteredPrograms,
      pages,
      languages,
      page,
      paginatedData,
      targets,
    };
  },
};
</script>

<style scoped></style>
